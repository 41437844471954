<template>
  <div class="container">
    <div class="list-header">
      <van-icon @click="$router.back(-1)" class="back-top-icon" name="arrow-left" />
      <div class="header-title">家长帮助</div>
      <div class="header-left">&nbsp;</div>
    </div>
    <div class="u-list">
      <div class="h85"></div>
      <div v-for="item in list" @click="$router.push({path: '/helper/detail', query: { deviceSn: item.deviceSn}})" :key="item.uuid" class="u-item">
        <img src="../../accesst/helper/device.png" alt="" srcset="">
        <div>
          <div class="u-name">{{ item.subUserName || '游客' }}</div>
          <div class="u-use">正在使用：{{  item.nowBrowse || '' }}</div>
          <div class="u-use">sn: {{ item.deviceSn }} </div>
        </div>
        <div class="live-icon">
          <span>&nbsp;</span>在线
        </div>
      </div>
    </div>
    <div v-show="!list || (list && list.length === 0)">
      <div class="no-data-bg">
        <div class="no-data-tip">当前无在线用户</div>
      </div>
      <!-- <div>&nbsp;</div> -->
      <div class="tips">若未绑定，请扫码绑定设备进行连接</div>
      <div class="tips">若已绑定，请将绑定设备开机</div>
    </div>
  </div>  
</template>

<script>
import { List, PullRefresh, Empty, Icon } from 'vant';
import moment from 'moment';
import { forEach } from 'lodash';
import { helpList } from '@/api/user.js'

export default {
  components: {[List.name]: List, [Empty.name]: Empty, [PullRefresh.name]: PullRefresh, [Icon.name]: Icon},
  data() {
    return {
      list:[{}]
    }
  },
  filters: {
    // 局部过滤器
    dateF: function(v) {
      if (!v) return '';
      return moment(v).format('yyyy-MM-DD HH:mm:ss')
    }
  },
  created() {
    helpList({}).then(res => {
      console.log(res, 'helplist')
      this.list = res.list;
    })
  },  
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background: #FFF;
  overflow: auto;
  .u-item {
    margin: .25rem .3rem;
    background: #F6F8F9;
    border-radius: .08rem;    
    padding: .27rem .19rem;
    display: flex;
    justify-content: space-between;
    img {
      width: 2.12rem;
      height: 1.6rem;
    }
    .u-name {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;    
      font-size: .35rem;
      color: #000000;
    }
    .u-use {
      padding-top: .15rem;
      font-weight: 400;
      width: 3.23rem;
      overflow: hidden;
      font-size: .27rem;
      color: #000000;
      line-height: .38rem;
      text-align: left;
      font-style: normal;
    }
    .live-icon {
      font-weight: 600;
      font-size: .27rem;
      color: #00AD81;
      line-height: .38rem;
      span {
        display: inline-block;
        width: .2rem;
        height: .2rem;
        border-radius: 50%;
        background: #00AD81;
        vertical-align: middle;
        margin-right: .05rem;
      }
    }
  }
  .no-data-bg {
    width: 3.62rem;
    height: 2.46rem;
    margin: 3rem auto 0;
    background: url('../../accesst/helper/no-data.png') no-repeat;
    background-size:3.62rem 2.46rem;
    .no-data-tip {
      padding-top: 1.96rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: .31rem;
      color: #000000;
      text-align: center;
    }
  }
  .tips {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: .23rem;
    color: #999999;
    line-height: .33rem;
    text-align: center;
  }
  .h85 {
    height: .85rem;
  }
  
  .list-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: .85rem;
    padding: 0 .46rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    .back-top-icon {
      width: .3rem;
      font-size: .32rem;
      color: #000;
      font-weight: bolder;
    }
    .header-title {
      font-size: .31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    .header-left {
      width: auto;
    }
  }
  .fixedStyle {
    position: fixed;
    top: .85rem;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
}
</style>  